<template>
  <div>
    <b-card
      bg-variant="light-success"
      class="mb-4 m-5"
    >
      <h3>Uyarı!</h3>
      <b-card-text class="mt-1 text-dark">
        Bu modülü vardiya başlangıcı yapmadan kullanamazsınız. Lütfen vardiya
        başlangıcı yapınız.
      </b-card-text>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="bgInfo"
        class="mt-1 mr-50"
        @click="vardiyaOpen"
      >
        Vardiya Başlat!
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="bgDanger"
        class="mt-1"
        @click="logOut"
      >
        Çıkış Yap
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BBreadcrumb,
  BButton,
  BButtonGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BButtonGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BBreadcrumb,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {}
  },
  computed: {
    isVardiya() {
      this.$store.getters.returnVardiya
    },
  },
  created() {
    localStorage.getItem('otelIsVardiya') == false ? this.$router.push('/') : ''
  },
  methods: {
    logOut() {
      this.$store.dispatch('logOut')
    },
    vardiyaOpen() {
      this.$store
        .dispatch('vardiyaOpen')
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          const mesaj = this.$store.getters.notificationSettings(res)
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            },
            { position },
          )
        })
        .catch(err => {
          const mesaj = this.$store.getters.notificationSettings(err)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Hata!',
              text: mesaj,
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped></style>
